<template>
  <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_116_1702)">
      <path d="M64.2353 44.4706V29.6471H69.1765V9.88235H54.3529V0H14.8235V9.88235H0V29.6471H4.94118V59.2941H45.096C44.6878 60.874 44.4706 62.5298 44.4706 64.2353C44.4706 75.1336 53.337 84 64.2353 84C75.1336 84 84 75.1336 84 64.2353C84 53.337 75.1336 44.4706 64.2353 44.4706ZM78.8518 61.7647H66.7059V49.6188C72.9039 50.6632 77.8074 55.5667 78.8518 61.7647ZM19.7647 4.94118H49.4118V9.88235H19.7647V4.94118ZM4.94118 14.8235H14.8235H54.3529H64.2353V24.7059H54.3529V19.7647H39.5294V24.7059H29.6471V19.7647H14.8235V24.7059H4.94118V14.8235ZM49.4118 24.7059V29.6471H44.4706V24.7059H49.4118ZM24.7059 24.7059V29.6471H19.7647V24.7059H24.7059ZM9.88235 49.4118H14.8235V54.3529H9.88235V49.4118ZM19.7647 54.3529V44.4706H9.88235V29.6471H14.8235V34.5882H29.6471V29.6471H39.5294V34.5882H54.3529V29.6471H59.2941V45.096C54.1115 46.4349 49.7468 49.8288 47.1238 54.3529H19.7647ZM64.2353 79.0588C56.0616 79.0588 49.4118 72.409 49.4118 64.2353C49.4118 56.9032 54.7629 50.7986 61.7647 49.6188V66.7059H78.8518C77.672 73.7077 71.5673 79.0588 64.2353 79.0588Z" fill="white"/>
      <path d="M22.2354 66.7058H39.5295V71.647H22.2354V66.7058Z" fill="white"/>
      <path d="M24.7061 76.5884H42.0002V81.5296H24.7061V76.5884Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_116_1702">
        <rect width="84" height="84" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconRoboClock"
}
</script>

<style scoped>

</style>