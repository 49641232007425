<template>
  <div class="kkt">
    <section class="kkt__top">
      <div class="kkt__seminar">
        <div class="kkt__seminar-date">
          <div class="kkt__seminar-date-icon">
            <img src="/pic/kkt/oae.svg" alt="Иконка" />
          </div>
          <div class="kkt__seminar-date-day">11</div>
          <div class="kkt__seminar-date-month">Апреля 2025</div>
          <div class="kkt__seminar-date-time">10:00</div>
        </div>
        <div class="kkt__seminar-text">
          <div>
            <div>
              <icon-seminar />&nbsp;&nbsp;&nbsp;&nbsp;Online-семинар
            </div>
            <h1 class="mt-2 mb-2">
              Кросс-культурная трансформация – взаимодействие с партнёрами из ОАЭ
            </h1>
            <div>
              Эксперт<br />
              <strong>Елисеев Вячеслав Сергеевич</strong>
            </div>
          </div>
          <div>
            Участие в семинаре бесплатно<br />
            <link-target link="https://www.exportcenter.ru/events_exportedu/716467/" class="mt-1">
              Записаться на семинар
            </link-target>
          </div>
        </div>
      </div>
    </section>
    <section class="kkt__first">
      <div class="container-shadow p-64 fill-white">
        <div class="d-flex align-items-center">
          <note-icon /> <h3 class="text-blue-03">Программа страновых онлайн-семинаров<br/> «Школа Кросс-культурной трансформации»</h3>
        </div>
        <div class="mt-2">
          <cube-list :list="[
              'Планируете выход на зарубежный рынок, но не знаете, как выстроить коммуникацию с представителем другой культуры? Гадаете, чего могут ожидать ваши иностранные партнеры на переговорах? Кросс-культурный анализ целевого рынка отнимает слишком много ресурсов?',
              'Закройте культурный вопрос для своего бизнеса – подключайтесь к страновым онлайн-семинарам нового проекта Школы экспорта РЭЦ «Школа кросс-культурной трансформации»!'
              ]" />
        </div>
      </div>
    </section>
    <section class="kkt__second">
      <div>
        <div class="d-flex">
          <div class="kkt__second_icon">
            <star-icon class="align-self-start" />
          </div>
          <div class="kkt__second_text">
            <h2 class="mb-2">Ключевая ценность программы</h2>
            Гармонично совмещает в себе деловые и культурные аспекты бизнеса в конкретной стране, позволяя взглянуть на процесс межкультурного делового взаимодействия глазами зарубежного партнера.
          </div>
        </div>
        <div class="d-flex mt-4">
          <div class="kkt__second_icon">
            <earth-icon class="align-self-start" />
          </div>
          <div class="kkt__second_text">
            <h2 class="mb-2">Страновые семинары для вас, если вы:</h2>
            <cube-list theme="white" :list="['уже экспортируете', 'планируете экспортировать']" />
          </div>
        </div>
      </div>
    </section>
    <section class="kkt__first">
      <div class="container-shadow long pr-64 pl-64 pt-45 pb-45 fill-white">
        <div class="d-flex align-items-center">
          <icon-globe/> <h3 class="text-blue-03">Почему именно страновые семинары?</h3>
        </div>
        <div class="mt-2">
          <cube-list :list="[
              'Удобно и быстро помогут вам освоиться на рынке целевой страны еще до выхода на рынок за счёт переформирования своего видения зарубежных партнеров и выработки нового подхода к деловому взаимодействию.',
              'Продолжительность каждого онлайн-семинара, посвященного отдельной стране - 1 день с 10:00 до 14:00. Страны, которые будут рассмотрены в 2025 г.:'
              ]" />
        </div>
        <div class="mt-2">
          <country-list />
        </div>
      </div>
    </section>
    <section class="kkt__third">
      <div>
        <div class="d-flex icon-item">
          <div class="icon-list">
            <icon-magneto />
          </div>
          <div class="icon-text">
            <h3 class="mb-2">
              Максимальная вовлеченность экспертов
            </h3>
            <div>
              Программа разработана и постоянно актуализируется высококлассными ВЭД-специалистами с большим практическим опытом работы и жизни в стране.
            </div>
          </div>
        </div>
        <div class="d-flex icon-item">
          <div class="icon-list">
            <icon-slide />
          </div>
          <div class="icon-text">
            <h3 class="mb-2">
              Фокус на практических результатах
            </h3>
            <div>
              При поддержке экспертов вы сможете спроецировать полученные знания на свой конкретный бизнес, взглянуть на себя глазами контрагента и сразу применить новые подходы в переговорном процессе.
            </div>
          </div>
        </div>
        <div class="d-flex icon-item">
          <div class="icon-list">
            <icon-robo-clock />
          </div>
          <div class="icon-text">
            <h3 class="mb-2">
              Компактная, но насыщенная программа
            </h3>
            <div>
              Вы получите максимум полезной информации при минимуме временных затрат.
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="kkt__first wide">
      <div class="container-shadow p-64 fill-white" >
        <div class="d-flex align-items-center">
          <icon-point/> <h3 class="text-blue-03">Что вы получите на семинарах:</h3>
        </div>
        <div class="mt-2">
          <cube-list :list="[
              'сформируете систему знаний о влиянии различных факторов (географических, исторических, культурных, религиозных, деловой/социальной среды и др.) на мировоззрение и психологию иностранного партнера, а также его восприятие российских деловых партнеров;',
              'получите возможность посмотреть на себя глазами иностранного партнера, сформировать новое понимание и «переосмыслить» свои стереотипы восприятия<br/>и модели поведения;',
              'изучите новые подходы к взаимодействию с зарубежными деловыми партнерами<br/>при помощи полученных знаний.'
          ]" />
        </div>
      </div>
    </section>
    <section class="kkt__tutor-list">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center kkt__tutor-list-header">
          <icon-expert /> <h2 class="m-0 p-0">Наши эксперты</h2>
        </div>
      </div>
      <div class="kkt__tutor__carousel owl-carousel">
        <div v-for="(expert, index) in experts" :key="index">
          <div class="kkt__tutor-item p-24">
            <div class="mb-2">
              <img :alt="expert.title" :src="'/pic/experts/' + expert.pic"/>
            </div>
            <h4 class="mb-1">
              {{ expert.title }}
            </h4>
            <div class="kkt__tutor_ul" v-html="expert.text"></div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="false" class="kkt__form">
      <div class="kkt__form-header">
        <div class="title-with-icon">
          <div class="title-with-icon__icon title-with-icon__icon_check"></div>
          <div class="title-with-icon__title">Запишитесь сейчас!</div>
        </div>
      </div>
      <div  class="kkt__form-inputs">
        <form @submit.prevent="submit" id="subscribe">
          <div class="form-item">
            <label>Ваше имя</label>
            <input class="form-control" v-model="form.fullname" required placeholder="Введите имя" name="name" />
          </div>
          <div class="form-item">
            <label>Ваш e-mail</label>
            <input class="form-control" v-model="form.email" required type="email" placeholder="Введите почту" name="name" />
          </div>
          <div class="form-item mt-4">
            <base-button-send text="Записаться на семинар" />
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import IconSeminar from "@/components/icons/IconSeminar.vue";
import NoteIcon from "@/components/icons/IconNote.vue";
import CubeList from "@/components/CubeList.vue";
import StarIcon from "@/components/icons/IconStar.vue";
import EarthIcon from "@/components/icons/IconEarth.vue";
import IconGlobe from "@/components/icons/IconGlobe.vue";
import CountryList from "@/components/CountryList.vue";
import IconMagneto from "@/components/icons/IconMagneto.vue";
import IconSlide from "@/components/icons/IconSlide.vue";
import IconRoboClock from "@/components/icons/IconRoboClock.vue";
import IconPoint from "@/components/icons/IconPoint.vue";
import LinkTarget from "@/components/LinkTarget.vue";
import IconExpert from "@/components/icons/IconExpert.vue";
import BaseButtonSend from "@/components/form/BaseButtonSend.vue";

export default {
  name: "Kkt",
  components: {
    BaseButtonSend,
    IconExpert,
    LinkTarget,
    IconPoint,
    IconRoboClock,
    IconSlide, IconMagneto, CountryList, IconGlobe, EarthIcon, StarIcon, CubeList, NoteIcon, IconSeminar },
  data() {
    return {
      form: {},
      experts: [
        {
          pic: 'maslov.png',
          title: 'Алексей Маслов',
          text: 'Директор, профессор, доктор исторических наук<br/> Институт Стран Азии и Африк МГУ им. М.В. Ломоносова<br/>' +
              '<ul>' +
                '<li>современное развитие стран Азии </li>' +
                '<li>политическое и социально-экономическое развитие Китая </li>' +
                '<li>национальные традиции</li>' +
                '<li>бизнес и предпринимательство в Азии </li>' +
              '</ul>'
        },
        {
          pic: 'starodubcev.png',
          title: 'Иван Стародубцев',
          text: '' +
              '<ul>' +
                '<li>Независимый эксперт-практик: российско-турецкие отношения, сделки ВЭД, кросс-культурная деловая коммуникация.</li>' +
                '<li>Эксперт Института Ближнего Востока, Российского совета по международным делам.</li>' +
                '<li>Автор книг: «Топливно-энергетический комплекс Турции и энергетический фактор в российско-турецких отношениях», «Трансформирующаяся Турция», «Россия – Турция: 500 лет беспокойного соседства», «Турецкие диалоги-1» и «Турецкие диалоги-2», Учебное пособие ШЭ РЭЦ «Турецкая Республика. Особенности ведения бизнеса» (в печати).</li>' +
                '<li>Ведущий авторских программ на радио и ТВ</li>' +
              '</ul>'
        },
        {
          pic: 'eliseev.png',
          title: 'Вячеслав Елисеев',
          text: 'Генеральный директор ООО КА "ДЖОБ ФОР АРАБИСТС"<br/>' +
          '<ul>' +
              '<li>Организация B2B встреч с крупными компаниями на территории ОАЭ, перевод и локализация информационно-презентационных материалов на арабский язык, подготовка представителей компании к участию в международных выставках.</li>' +
              '<li>2021- 2022: организация деловых миссий в г. Дубай для компаний </li>' +
              '<li>2019: выступление в роли лектора практики в рамках 6th Access meeting Export Workshop «GLOBAL GULF TRADE» по теме «Экспорт в страны Персидского залива: результативный ВЭД и работа над ошибками».</li>' +
          '</ul>'
        },
        {
          pic: 'sadukzade.png',
          title: 'Садыгзаде Мурад Салех Оглы',
          text: 'Президент Российской ближневосточной ассоциации, приглашённый преподаватель Департамента зарубежного регионоведения НИУ ВШЭ, эксперт ливанского аналитического центра «Истишариа».<br/>' +
              '<ul>' +
                '<li>Президент Центра ближневосточных исследований</li>' +
                '<li>Приглашенный преподаватель НИУ ВШЭ, РАНХиГС, МГИМО</li>' +
                '<li>Эксперт Российского совета по международным делам</li>' +
                '<li>Эксперт ливанского форума Истишариа</li>' +
                '<li>Частный консультант по политическим рисками в БВСА</li>' +
              '</ul>'
        },
        {
          pic: 'griva.png',
          title: 'Грива Евгений Владимирович',
          text: 'Заместитель Торгового представителя Российской Федерации в Индии, экс-генеральный директор SIBUR Petrochemical India Pvt. Ltd., кандидат психологических наук, МВА, автор книги «Волшебная Индия, или как жить и управлять людьми в этой стране»<br/>' +
              '<ul>' +
                '<li>Кросс-культурный менеджмент</li>' +
                '<li>Международный бизнес</li>' +
                '<li>Управление персоналом в Южной Азии</li>' +
              '</ul>'
        },
        {
          pic: 'veselova.png',
          title: 'Людмила Веселова',
          text: 'Академический руководитель магистерских программ «Бизнес и политика в современной Азии» и «Международный бизнес в АТР», доцент, кандидат исторических наук НИУ ВШЭ (Санкт-Петербург)<br/>' +
              '<ul>' +
                '<li>7 лет активной работы с китайскими поставщиками и заводами, координация деятельности заводов в КНР.</li>' +
                '<li>Участие в проектах-заказах от РЖД по созданию осветительных приборов для локомотивов.</li>' +
                '<li>Опыт работы с компаниями, специализирующихся на сувенирной продукции, опыт реализации успешных проектов для компаний «Связной», «Сибирское здоровье», «Coca-Cola» и т.д.</li>' +
              '</ul>'
        }
      ]
    }
  },
  mounted() {
    this.$parent.init()
  },
  methods: {
    submit() {
      return false;
    }
  }
}
</script>
<style lang="scss" scoped>
.kkt {
  padding-top: 18rem;
  color: #fff;

  section {
    height: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__top {
    background: url("/pic/kkt/oae.png") no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
  }

  &__seminar {
    color: #fff;
    display: flex;
    width: 100rem;
    align-items: center;

    &-date {
      height: 44rem;
      text-align: center;
      flex: 1;
      border: 1px solid #fff;
      padding: 7.2rem 2.4rem;

      &-icon {
        // Стили для иконки, если нужны
      }

      &-day {
        font-size: 12rem;
        line-height: 1;
        font-weight: 300;
        margin-top: 3.2rem;
      }

      &-month {
        font-size: 2.4rem;
        font-weight: 200;
      }

      &-time {
        font-size: 2rem;
        font-weight: 200;
      }
    }

    &-text {
      height: 44rem;
      flex: 3;
      padding-left: 6.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h1 {
        font-weight: bold;
      }

      .link-target {
        color: #fff;
      }
    }
  }

  &__first {
    width: 120rem;
    background: #FAFAFA;
    margin: 0 auto;
    height: auto;

    > div {
      width: 100%;
      padding-right: 34.2rem;

      h3 {
        margin-left: 3.2rem;
        font-weight: bold;
        font-size: 2.8rem;
        margin-bottom: 0;
      }
    }

    &.wide {
      width: 120rem;

      > div {
        padding-right: 28rem;
      }
    }
  }

  .container-shadow {
    background: url("/pic/red-arrow-right.svg") no-repeat calc(100% - 2.4rem) top;

    &.long {
      background-image: url("/pic/red-arrow-right-long.svg");
    }
  }

  &__second {
    background: url("/pic/kkt/chinese.png") no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

    > div {
      width: 90rem;
    }

    &_icon {
      width: 84px;
      margin-right: 2.4rem;
    }

    &_text {
      h2 {
        margin: 0;
        font-size: 3.6rem;
        font-weight: bold;
      }

      font-size: 2.4rem;
      font-weight: 300;
    }
  }

  &__third {
    background: url("/pic/kkt/uz.png") no-repeat;
    background-size: cover;

    > div {
      width: 90rem;
    }

    .icon-list {
      flex: 0 0 140px;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .icon-text {
      flex: 1;

      h3 {
        font-size: 2.8rem;
        font-weight: bold;
      }

      font-size: 2rem;
      font-weight: 300;
    }

    .icon-item {
      margin-bottom: 6.4rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__tutor-list {
    background: #002E5E;
    padding-left: calc((100% - 120rem)/2);
    display: block!important;
    height: 42vw!important;
    &-header {
      font-size: 2.8rem;
      margin: 4rem 0;
      svg {
        margin-right: 14px;
      }
    }
    &-arrow {
      padding-right: calc((100% - 120rem));
      svg {
        margin-left: 4.8rem;
      }
    }
  }

  &__tutor-item {
    width: 37rem;
    height: 62.1rem;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    color: #333;
    font-size: 1.5rem;

    h4 {
      font-size: 2.2rem;
      font-weight: bold;
    }

    img {
      width: 14rem;
      margin: 0 auto;
    }
  }
  &__form {
    height: auto!important;
    display: block!important;
    padding-left: calc((100% - 120rem)/2);
    background: #fff;
    padding-top: 7rem;
    padding-bottom: 7rem;
    flex-direction: column;
    &-header {
      display: flex;
      align-items: center;
      color: #002E5E;
      margin-bottom: 4rem;
    }
    &-inputs {
      width: 60rem;
    }
  }
}

// Медиа-запрос для экранов с шириной 768px и меньше
@media (max-width: 768px) {
  .kkt {
    padding-top: 0; // 18rem -> 288px
    margin-top: -96px;
    section {
      height: auto;
    }

    &__seminar {
      width: 100%; // Занимаем всю доступную ширину
      flex-direction: column; // Меняем направление flex на вертикальное

      &-date {
        height: 440px; // 44rem -> 704px
        border: none;
        padding: 72px 24px 0; // 7.2rem -> 115px, 2.4rem -> 38px

        &-day {
          font-size: 120px; // 12rem -> 192px
          margin-top: 32px; // 3.2rem -> 51px
        }

        &-month {
          font-size: 24px; // 2.4rem -> 38px
        }

        &-time {
          font-size: 20px; // 2rem -> 32px
        }
      }

      &-text {
        height: auto; // Сбрасываем фиксированную высоту
        padding-left: 0; // Убираем отступ слева
        margin-top: 20px; // Добавляем отступ сверху
        text-align: center;
        padding-bottom: 40px;
      }
    }

    &__first {
      width: 100%; // Занимаем всю ширину

      > div {
        padding-right: 24px; // Убираем отступ справа
        padding-left: 24px;

        h3 {
          margin-left: 10px; // 3.2rem -> 51px (примерно)
          font-size: 18px; // 2.8rem -> 45px
        }
        svg {
          width: 42px;
        }
      }

      &.wide {
        width: 100%;

        > div {
          padding-right: 0;
        }
      }
    }

    &__second {
      > div {
        width: 100%; // Занимаем всю ширину
        padding: 10px;
      }

      &_icon {
        margin-right: 10px; // 2.4rem -> 38px (примерно)
        width: 36px;
        svg {
          width: 28px;
          height:40px;
        }
      }

      &_text {
        h2 {
          font-size: 24px; // 3.6rem -> 58px
        }

        font-size:16px; // 2.4rem -> 38px
      }
    }

    &__third {
      > div {
        width: 100%; // Занимаем всю ширину
      }

      .icon-text {
        h3 {
          font-size: 18px; // 2.8rem -> 45px
        }

        font-size: 12px; // 2rem -> 32px
      }

      .icon-list {
        flex: 0 0 42px;
        align-items: start;
        svg {
          width: 28px;
          height: 36px;
        }
      }

      .icon-item {
        margin-top: 24px!important;
        margin-bottom: 24px!important;
      }
    }

    &__tutor-list {
      padding-left: 20px; // 30rem -> 480px (примерно)
      display: block!important;
      height: auto!important;
      text-align: center;
      &-header {
        margin: 40px 0;
        h2 {
          font-size: 18px!important;
        }
      }
    }

    &__tutor-item {
      width: 100%; // Занимаем всю ширину
      height: auto; // Сбрасываем фиксированную высоту
      font-size: 15px;
      h4 {
        font-size: 22px; // 2.2rem -> 35px
      }

      img {
        width: 150px; // 15rem -> 240px
      }
    }
    .container-shadow {
      background: none;
      &.long {
        background: none;
      }
    }
    &__form {
      padding-left: 24px;
    }
  }
  .link-target {
    color: #002E5E!important;
  }
}
</style>
