<template>
  <ul class="cube-list" :class="theme">
    <li class="cube-list__item" v-for="(list, index) in list" :key="index">
      <span v-html="list"></span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "CubeList",
  props: {
    list: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      default() {
        return 'blue'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cube-list {
  list-style: none;
  margin: 0;
  padding: 0;
  &__item {
    position: relative;
    padding-left: 3.2rem; // Отступ для размещения иконки
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #002E5E;

    &::before {
      content: "";
      position: absolute;
      top: 2rem;
      left: 0;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M20.4701%207.37005C20.4701%207.37005%2020.4701%207.37005%2020.4701%207.29005L20.4101%207.14005C20.3893%207.10822%2020.3659%207.07812%2020.3401%207.05005C20.3134%207.00772%2020.2833%206.96759%2020.2501%206.93005L20.1601%206.86005L20.0001%206.78005L12.5001%202.15005C12.3412%202.05072%2012.1576%201.99805%2011.9701%201.99805C11.7827%201.99805%2011.5991%202.05072%2011.4401%202.15005L4.00014%206.78005L3.91015%206.86005L3.82014%206.93005C3.787%206.96759%203.7569%207.00772%203.73015%207.05005C3.70444%207.07812%203.68103%207.10822%203.66015%207.14005L3.60014%207.29005C3.60014%207.29005%203.60014%207.29005%203.60014%207.37005C3.59032%207.45644%203.59032%207.54366%203.60014%207.63005V16.3701C3.5998%2016.54%203.64278%2016.7072%203.72502%2016.8559C3.80725%2017.0047%203.92603%2017.13%204.07015%2017.22L11.5701%2021.85C11.6163%2021.8786%2011.6671%2021.8989%2011.7201%2021.91C11.7201%2021.91%2011.7701%2021.91%2011.8001%2021.91C11.9693%2021.9637%2012.151%2021.9637%2012.3201%2021.91C12.3201%2021.91%2012.3701%2021.91%2012.4001%2021.91C12.4532%2021.8989%2012.504%2021.8786%2012.5501%2021.85L20.0001%2017.22C20.1443%2017.13%2020.263%2017.0047%2020.3453%2016.8559C20.4275%2016.7072%2020.4705%2016.54%2020.4701%2016.3701V7.63005C20.48%207.54366%2020.48%207.45644%2020.4701%207.37005ZM11.0001%2019.21L5.50014%2015.8101V9.43005L11.0001%2012.82V19.21ZM12.0001%2011.09L6.40015%207.63005L12.0001%204.18005L17.6001%207.63005L12.0001%2011.09ZM18.5001%2015.8101L13.0001%2019.21V12.82L18.5001%209.43005V15.8101Z%22%20fill%3D%22%23002E5E%22/%3E%3C/svg%3E");
    }
  }
  &.white {
    .cube-list__item {
      padding-left: 4rem;
      color: #fff;
      font-size: 2.4rem;
      &::before {
        top: 1.5rem;
        background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.4701 7.37005C20.4701 7.37005 20.4701 7.37005 20.4701 7.29005L20.4101 7.14005C20.3893 7.10822 20.3659 7.07812 20.3401 7.05005C20.3134 7.00772 20.2833 6.96759 20.2501 6.93005L20.1601 6.86005L20.0001 6.78005L12.5001 2.15005C12.3412 2.05072 12.1576 1.99805 11.9701 1.99805C11.7827 1.99805 11.5991 2.05072 11.4401 2.15005L4.00014 6.78005L3.91015 6.86005L3.82014 6.93005C3.787 6.96759 3.7569 7.00772 3.73015 7.05005C3.70444 7.07812 3.68103 7.10822 3.66015 7.14005L3.60014 7.29005C3.60014 7.29005 3.60014 7.29005 3.60014 7.37005C3.59032 7.45644 3.59032 7.54366 3.60014 7.63005V16.3701C3.5998 16.54 3.64278 16.7072 3.72502 16.8559C3.80725 17.0047 3.92603 17.13 4.07015 17.22L11.5701 21.85C11.6163 21.8786 11.6671 21.8989 11.7201 21.91C11.7201 21.91 11.7701 21.91 11.8001 21.91C11.9693 21.9637 12.151 21.9637 12.3201 21.91C12.3201 21.91 12.3701 21.91 12.4001 21.91C12.4532 21.8989 12.504 21.8786 12.5501 21.85L20.0001 17.22C20.1443 17.13 20.263 17.0047 20.3453 16.8559C20.4275 16.7072 20.4705 16.54 20.4701 16.3701V7.63005C20.48 7.54366 20.48 7.45644 20.4701 7.37005ZM11.0001 19.21L5.50014 15.8101V9.43005L11.0001 12.82V19.21ZM12.0001 11.09L6.40015 7.63005L12.0001 4.18005L17.6001 7.63005L12.0001 11.09ZM18.5001 15.8101L13.0001 19.21V12.82L18.5001 9.43005V15.8101Z" fill="white"/></svg>');
      }
    }
  }
}

@media (max-width: 768px) {
  .cube-list {
    &__item {
      &::before {
        width: 16px;
        height: 16px;
      }
    }
  }
}

</style>