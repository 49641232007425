<template>
  <div class="country-list container-grid grid-4">
    <div class="container-grid__item">
      <country-list-item title="Турция" pic="tu" />
      <country-list-item title="ОАЭ" pic="oae" />
      <country-list-item title="Индия" pic="ind" />
    </div>
    <div class="container-grid__item">
      <country-list-item title="Египет" pic="eg" />
      <country-list-item title="Китай" pic="ch" />
      <country-list-item title="Азербайджан" pic="az" />
    </div>
    <div class="container-grid__item">
      <country-list-item title="Узбекистан" pic="uz" />
      <country-list-item title="Иран" pic="ir" />
    </div>
  </div>
</template>

<script>
import CountryListItem from "@/components/CountryListItem.vue";

export default {
  name: "CountryList",
  components: {CountryListItem}
}
</script>
<style lang="scss" scoped>
.country-list {
  .container-grid__item {
    padding-bottom: 13px;
  }
  .container-grid__item > * {
    height: auto;
    margin-top: 10px;
  }
}

</style>