<template>
  <div class="d-flex align-items-center country-list__item">
    <img :alt="title" :src="'/countries/' + pic + '.png'" />
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "CountryListItem",
  props: {
    title: {
      type: String,
      required: true
    },
    pic: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.country-list__item {
  color: #002E5E;
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  span {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .country-list__item {
    span {
      font-size: 12px;
    }
  }
}
</style>