<template>
  <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_116_1666)">
      <path d="M51.882 24.7058V54.3529C51.882 59.802 47.4487 64.2352 41.9996 64.2352C36.5505 64.2352 32.1172 59.802 32.1172 54.3529V24.7058H12.3525V54.3529C12.3525 70.7003 25.6522 83.9999 41.9996 83.9999C58.347 83.9999 71.6467 70.7003 71.6467 54.3529V24.7058H51.882ZM66.7055 29.647V34.5882H56.8231V29.647H66.7055ZM27.1761 29.647V34.5882H17.2937V29.647H27.1761ZM41.9996 79.0588C28.3768 79.0588 17.2937 67.9757 17.2937 54.3529V39.5293H27.1761V54.3529C27.1761 62.5266 33.8259 69.1764 41.9996 69.1764C50.1733 69.1764 56.8231 62.5266 56.8231 54.3529V39.5293H66.7055V54.3529C66.7055 67.9757 55.6224 79.0588 41.9996 79.0588Z" fill="white"/>
      <path d="M39.6928 23.8215L44.3066 25.5901L49.381 12.3528H43.235L45.9273 5.89143L41.3663 3.99072L35.8232 17.294H42.1949L39.6928 23.8215Z" fill="white"/>
      <path d="M14.8232 4.94116H19.7644V19.7647H14.8232V4.94116Z" fill="white"/>
      <path d="M24.7051 0H29.6463V19.7647H24.7051V0Z" fill="white"/>
      <path d="M64.2354 4.94116H69.1765V19.7647H64.2354V4.94116Z" fill="white"/>
      <path d="M54.3525 0H59.2937V19.7647H54.3525V0Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_116_1666">
        <rect width="84" height="84" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconMagneto"
}
</script>

<style scoped>

</style>