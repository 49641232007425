<template>
  <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M77.0002 33.845C76.7791 33.2048 76.3771 32.6425 75.8428 32.2263C75.3085 31.81 74.665 31.5578 73.9902 31.5L54.0752 28.595L45.1502 10.5C44.8636 9.90827 44.4161 9.40922 43.859 9.06003C43.3019 8.71084 42.6577 8.52563 42.0002 8.52563C41.3427 8.52563 40.6985 8.71084 40.1414 9.06003C39.5842 9.40922 39.1368 9.90827 38.8502 10.5L29.9252 28.56L10.0102 31.5C9.36239 31.5921 8.7534 31.8639 8.25229 32.2846C7.75118 32.7053 7.37802 33.258 7.17517 33.88C6.98947 34.4879 6.97281 35.1348 7.12696 35.7514C7.28112 36.3681 7.60027 36.9311 8.05017 37.38L22.5052 51.38L19.0052 71.26C18.8639 71.9195 18.9161 72.6058 19.1556 73.2362C19.3951 73.8667 19.8117 74.4146 20.3552 74.8139C20.8987 75.2132 21.5461 75.447 22.2193 75.4871C22.8926 75.5272 23.5631 75.372 24.1502 75.04L42.0002 65.695L59.8502 75.04C60.3414 75.3171 60.8962 75.4619 61.4602 75.46C62.2016 75.4627 62.9246 75.2298 63.5252 74.795C64.0682 74.406 64.4885 73.8698 64.7366 73.2496C64.9847 72.6294 65.0501 71.9512 64.9252 71.295L61.4252 51.415L75.8802 37.415C76.3855 36.9869 76.759 36.4242 76.9572 35.7922C77.1555 35.1603 77.1704 34.4851 77.0002 33.845ZM55.4752 47.845C55.0701 48.2384 54.7662 48.724 54.5894 49.2604C54.4127 49.7967 54.3683 50.3678 54.4602 50.925L56.9802 65.625L43.8202 58.625C43.3087 58.372 42.7458 58.2404 42.1752 58.2404C41.6045 58.2404 41.0416 58.372 40.5302 58.625L27.3702 65.625L29.8902 50.925C29.982 50.3678 29.9377 49.7967 29.7609 49.2604C29.5842 48.724 29.2803 48.2384 28.8752 47.845L18.3752 37.345L33.1102 35.21C33.6772 35.1312 34.2162 34.9144 34.6799 34.5788C35.1437 34.2432 35.518 33.799 35.7702 33.285L42.0002 19.95L48.5802 33.32C48.8323 33.834 49.2067 34.2782 49.6704 34.6138C50.1342 34.9494 50.6732 35.1662 51.2402 35.245L65.9752 37.38L55.4752 47.845Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "StarIcon"
}
</script>

<style scoped>

</style>