<template>
  <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M84 66.6094V2.625H0V66.6094H29.6953V76.4531H19.8516V81.375H64.1484V76.4531H54.3047V66.6094H84ZM79.0781 7.54688V51.8438H4.92188V7.54688H79.0781ZM4.92188 56.7656H79.0781V61.6875H4.92188V56.7656ZM49.3828 76.4531H34.6172V66.6094H49.3828V76.4531Z" fill="white"/>
    <path d="M42 28.2537L51.8438 38.0974L69.0703 20.8709V24.7734H73.9922V12.4688H61.6875V17.3906H65.5901L51.8438 31.1369L42 21.2932L21.2932 42H10.0078V46.9219H23.3318L42 28.2537Z" fill="white"/>
    <path d="M10.0078 12.4688H29.6953V17.3906H10.0078V12.4688Z" fill="white"/>
    <path d="M10.0078 22.3125H29.6953V27.2344H10.0078V22.3125Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "IconSlide"
}
</script>

<style scoped>

</style>