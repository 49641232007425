<template>
  <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_116_1571)">
      <path d="M44.3363 7.38281H36.9534C36.9534 5.41144 36.1853 3.5577 34.7904 2.16284C33.3956 0.76814 31.5418 0 29.5706 0C25.4997 0 22.1878 3.31193 22.1878 7.38281H14.805V12.3067L0 12.3042V84H59.0625V12.3047H44.3363V7.38281ZM19.7269 12.3047H27.1097V7.38281C27.1097 6.02585 28.2137 4.92188 29.5706 4.92188C30.2272 4.92188 30.8451 5.17814 31.3103 5.64309C31.7755 6.10821 32.0316 6.72607 32.0316 7.38281V12.3047H39.4144V17.2266H19.7269V12.3047ZM54.1406 17.2266V79.0781H4.92188V17.2269L14.805 17.2285V22.1484H44.3363V17.2266H54.1406Z" fill="#002E5E"/>
      <path d="M76.6172 16.9312C72.5463 16.9312 69.2344 20.2431 69.2344 24.314V67.6496L76.6172 82.4152L84 67.6496V24.314C84 20.2431 80.6881 16.9312 76.6172 16.9312ZM76.6172 21.853C77.9741 21.853 79.0781 22.957 79.0781 24.314V26.7749H74.1562V24.314C74.1562 22.957 75.2602 21.853 76.6172 21.853ZM76.6172 71.4096L74.1562 66.4877V31.6968H79.0781V66.4877L76.6172 71.4096Z" fill="#002E5E"/>
      <path d="M24.6094 28.4648H9.84375V43.2305H24.6094V28.4648ZM19.6875 38.3086H14.7656V33.3867H19.6875V38.3086Z" fill="#002E5E"/>
      <path d="M29.4258 28.4648H49.2183V33.3867H29.4258V28.4648Z" fill="#002E5E"/>
      <path d="M29.4258 38.3086H49.2183V43.2305H29.4258V38.3086Z" fill="#002E5E"/>
      <path d="M9.84375 48.1523H49.2188V53.0742H9.84375V48.1523Z" fill="#002E5E"/>
      <path d="M9.84375 57.9961H49.2188V62.918H9.84375V57.9961Z" fill="#002E5E"/>
      <path d="M9.84375 67.8398H49.2188V72.7617H9.84375V67.8398Z" fill="#002E5E"/>
    </g>
    <defs>
      <clipPath id="clip0_116_1571">
        <rect width="84" height="84" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "NoteIcon"
}
</script>

<style scoped>

</style>