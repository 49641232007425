<template>
  <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M74.9352 30.24C74.9352 30.24 74.9352 30.24 74.9352 30.065C72.4691 23.3317 67.9944 17.5184 62.1164 13.4115C56.2384 9.3045 49.2408 7.10205 42.0702 7.10205C34.8996 7.10205 27.902 9.3045 22.024 13.4115C16.146 17.5184 11.6713 23.3317 9.2052 30.065C9.2052 30.065 9.2052 30.065 9.2052 30.24C6.45113 37.8381 6.45113 46.1618 9.2052 53.76C9.2052 53.76 9.2052 53.76 9.2052 53.935C11.6713 60.6682 16.146 66.4815 22.024 70.5884C27.902 74.6954 34.8996 76.8979 42.0702 76.8979C49.2408 76.8979 56.2384 74.6954 62.1164 70.5884C67.9944 66.4815 72.4691 60.6682 74.9352 53.935C74.9352 53.935 74.9352 53.935 74.9352 53.76C77.6893 46.1618 77.6893 37.8381 74.9352 30.24ZM14.9102 49C13.6965 44.4122 13.6965 39.5877 14.9102 35H21.4202C20.8603 39.6498 20.8603 44.3501 21.4202 49H14.9102ZM17.7802 56H22.6802C23.5017 59.1214 24.6759 62.1392 26.1802 64.995C22.7477 62.6567 19.8784 59.5842 17.7802 56ZM22.6802 28H17.7802C19.8482 24.4268 22.6809 21.3551 26.0752 19.005C24.6071 21.8653 23.4682 24.8829 22.6802 28ZM38.5002 68.95C34.2014 65.7953 31.1822 61.1983 29.9952 56H38.5002V68.95ZM38.5002 49H28.4902C27.8371 44.3561 27.8371 39.6438 28.4902 35H38.5002V49ZM38.5002 28H29.9952C31.1822 22.8016 34.2014 18.2046 38.5002 15.05V28ZM66.2202 28H61.3202C60.4987 24.8785 59.3245 21.8607 57.8202 19.005C61.2527 21.3432 64.122 24.4157 66.2202 28ZM45.5002 15.05C49.799 18.2046 52.8182 22.8016 54.0052 28H45.5002V15.05ZM45.5002 68.95V56H54.0052C52.8182 61.1983 49.799 65.7953 45.5002 68.95ZM55.5102 49H45.5002V35H55.5102C56.1633 39.6438 56.1633 44.3561 55.5102 49ZM57.9252 64.995C59.4295 62.1392 60.6037 59.1214 61.4252 56H66.3252C64.227 59.5842 61.3577 62.6567 57.9252 64.995ZM69.0902 49H62.5802C62.8648 46.6776 63.0051 44.3397 63.0002 42C63.004 39.6602 62.8638 37.3225 62.5802 35H69.0902C70.3039 39.5877 70.3039 44.4122 69.0902 49Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "EarthIcon"
}
</script>

<style scoped>

</style>